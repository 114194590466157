import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import Hero from '../components/hero'

const Layout = ({ location, title, hero, children }) => {
  const [scrolled, setScrolled] = useState(false)
  // const rootPath = `${__PATH_PREFIX__}/`

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
  })

  const handleScroll = e => {
    const scrolledTop = window.scrollY

    if (scrolledTop > 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const header = scrolled ? 'header bg-black' : 'header bg-transparent'
  const logo = scrolled
    ? 'logo__svg logo__svg--scrolled'
    : ' logo__svg logo__svg--not-scrolled'

  return (
    <ParallaxProvider>
      <div className="wrapper">
        <header className={header}>
          <div className="header__inner">
            <Link className="logo" to={`/`}>
              <svg className={logo} viewBox="0 0 82 154">
                <path
                  className="logo__svg-path"
                  fill="#fafafa"
                  d="M20.7 141.6L27.8 142.1C41.5 143.1 54.3 136.7 61 128.1 64.4 123.7 70.7 110.4 66.5 98.6 63.6 91.2 56.5 83 40.6 69.2 22.8 53.5 20.8 49.1 22.5 37.8 24.6 23.5 40.6 16.7 52.4 18.7 55.6 19.3 57.5 20.2 58.2 21.4L56.3 29C43.7 26.1 35 28.7 35 40 35 54.6 44.5 59.4 49.4 61.5L73.8 47.8 75.5 59.7 61 69.5 75.5 88.3C85.3 104.7 82.1 124.7 67.5 139.7 58 149.5 37.2 155.9 22 153.5L17.8 152.9 20.7 141.6ZM22.6 134.9L25 123.6C35.4 123.6 41.6 121.4 43.5 116.9 46.4 110.2 47.2 107 45.8 103.5 44.2 99.1 36.7 91 25 81.3 6.5 65.7-0.2 54.5 0 40 0.4 18 21.7 0.2 47.8 0 52.5 0 57.8 0.9 63.5 2.8L61 13.3 53.9 12.2C45.8 10.6 36.4 11.9 27.7 17.2 18.4 23 14.5 28.7 13.3 38.3 12 50.2 16.2 57.6 33.1 72.1 55 90.9 61.2 100.2 59 111.6 56.7 123.5 48.7 132.2 37.5 134.9 32.3 136.1 27.3 136.1 22.6 134.9Z"
                />
              </svg>
              <h1 className="logo__text">Superfresh</h1>
            </Link>
            <nav className="header__nav">
              <ul className="header__nav-list">
                <li>
                  <Link
                    className="header__nav-list-item"
                    activeClassName="text-primary"
                    to="/"
                    exact
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="header__nav-list-item"
                    activeClassName="text-primary"
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className="header__nav-list-item"
                    activeClassName="text-primary"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <Hero data={hero} />
        <main className="main">{children}</main>
        <footer className="footer">
          <div className="footer__inner">
            <div className="footer__col">
              <div className="flex flex-col items-start">
                <p className="footer__email">
                  <a className="block" href="mailto:hello@superfre.sh">
                    hello@superfre.sh
                  </a>
                </p>
              </div>
            </div>
            <nav className="footer__col pb-12">
              <ul className="footer__nav">
                <li className="footer__nav-item">
                  <Link
                    className="footer__nav-item-link"
                    activeClassName="text-primary"
                    exact
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="footer__nav-item">
                  <Link
                    className="footer__nav-item-link"
                    activeClassName="text-primary"
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li className="footer__nav-item">
                  <Link
                    className="footer__nav-item-link"
                    activeClassName="text-primary"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
                <li className="footer__nav-item">
                  <a
                    className="footer__nav-item-link"
                    href="https://app.termly.io/document/privacy-policy/41734a18-5a19-404b-aa02-19019032aa3a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </nav>
            <div className="footer__col pb-12">
              <svg className="footer__logo" viewBox="0 0 82 154">
                <path
                  fill="#fafafa"
                  d="M20.7 141.6L27.8 142.1C41.5 143.1 54.3 136.7 61 128.1 64.4 123.7 70.7 110.4 66.5 98.6 63.6 91.2 56.5 83 40.6 69.2 22.8 53.5 20.8 49.1 22.5 37.8 24.6 23.5 40.6 16.7 52.4 18.7 55.6 19.3 57.5 20.2 58.2 21.4L56.3 29C43.7 26.1 35 28.7 35 40 35 54.6 44.5 59.4 49.4 61.5L73.8 47.8 75.5 59.7 61 69.5 75.5 88.3C85.3 104.7 82.1 124.7 67.5 139.7 58 149.5 37.2 155.9 22 153.5L17.8 152.9 20.7 141.6ZM22.6 134.9L25 123.6C35.4 123.6 41.6 121.4 43.5 116.9 46.4 110.2 47.2 107 45.8 103.5 44.2 99.1 36.7 91 25 81.3 6.5 65.7-0.2 54.5 0 40 0.4 18 21.7 0.2 47.8 0 52.5 0 57.8 0.9 63.5 2.8L61 13.3 53.9 12.2C45.8 10.6 36.4 11.9 27.7 17.2 18.4 23 14.5 28.7 13.3 38.3 12 50.2 16.2 57.6 33.1 72.1 55 90.9 61.2 100.2 59 111.6 56.7 123.5 48.7 132.2 37.5 134.9 32.3 136.1 27.3 136.1 22.6 134.9Z"
                />
              </svg>
              <p className="footer__branding">
                <span className="footer__branding-name">Superfresh</span>A
                Digital Product Studio in{' '}
                <a
                  className="footer__branding-location"
                  href="https://en.m.wikipedia.org/wiki/St._Petersburg,_Florida"
                  target="_blank"
                  rel="noreferrer"
                >
                  St. Pete, FL
                </a>
              </p>
            </div>
          </div>
          <p className="footer__copyright">
            &copy; copyright {new Date().getFullYear()} Superfresh, LLC
          </p>
        </footer>
      </div>
    </ParallaxProvider>
  )
}

export default Layout
