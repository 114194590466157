import React from 'react'
import { Parallax } from 'react-scroll-parallax'

export default function Hero({ data }) {
  const ParallaxImage = () => (
    <Parallax y={[-20, 20]} tagOuter="figure">
      <div
        className="hero__img"
        style={{ backgroundImage: `url(${data.img})` }}
      />
    </Parallax>
  )

  const fullTitle = text => {
    const headline = text.split(' ')
    const last = headline.pop()

    return (
      headline.join(' ') +
      (headline.length > 0
        ? ' <strong class="hero__title-accent">' + last + '</strong>'
        : last)
    )
  }

  return (
    <div className="hero">
      <div className="hero__img-wrap" style={{ zIndex: 0 }}>
        <ParallaxImage />
        <div className="hero__gradient" />
      </div>
      <div className="hero__inner-wrap">
        <div className="hero__inner">
          <h1
            className="hero__title"
            dangerouslySetInnerHTML={{
              __html: fullTitle(data.title).replace(
                /(<? *script)/gi,
                'illegalscript'
              ),
            }}
          />
          <h2 className="hero__subtitle">{data.subtitle}</h2>
        </div>
      </div>
    </div>
  )
}
